import React from "react";
import { BillingPeriodUnit, Plan } from "services/graphql";
import Button from "app/components/Button";
import { useIsSmallScreen } from "hooks/index";
import { setSelectedPlan } from "modules/checkout";
import { useDispatch } from "react-redux";
import { centsToDollars } from "helpers/centsToDollars";
import { AbbreviatedPeriodUnits } from "constants/cart";
import RadioInputChecked from "app/assets/svg/radio-input-checked.svg";
import RadioInputUnchecked from "app/assets/svg/radio-input-unchecked.svg";
import { useUserHasTrialed } from "hooks/Subscriptions/useUserHasTrialed";
import styles from "./styles.module.scss";
import { LogoRatings } from "./LogoRatings";
import { BenefitsText } from "./BenefitsText";

interface Props {
  headerText: string;
  continueToCheckout(): void;
  plans: Plan[];
  selectedBillingPeriod: BillingPeriodUnit;
  setSelectedBillingPeriod(billingPeriod: BillingPeriodUnit): void;
  selectedPlan: Plan;
}

export function PlanSelect({
  headerText,
  continueToCheckout,
  plans,
  selectedBillingPeriod,
  setSelectedBillingPeriod,
  selectedPlan,
}: Props) {
  const isSmallScreen = useIsSmallScreen();
  const dispatch = useDispatch();
  const { hasTrialed } = useUserHasTrialed();
  const { canTrial: canSelectedPlanTrial } = selectedPlan || {};
  const canTrial = canSelectedPlanTrial && !hasTrialed;

  if (!plans) {
    // @TODO: Add a loader
    return null;
  }

  if (!selectedPlan) {
    // @TODO: Add a loader
    return null;
  }

  function generateSubHeaderText() {
    if (canTrial) {
      return "$0 due today";
    }

    return "Choose A Plan";
  }

  const selectPlan = (plan: Plan) => dispatch(setSelectedPlan(plan));

  return (
    <>
      <div className={styles.container}>
        <div className={styles.planSelectContainer}>
          <div className={styles.headerContainer}>
            {isSmallScreen ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <LogoRatings />
                <div className={styles.flexBetweenContainer}>
                  <h1 className={styles.header}>{headerText}</h1>
                </div>
              </div>
            ) : (
              <div className={styles.flexBetweenContainer}>
                <h1 className={styles.header}>{headerText}</h1>
                <LogoRatings />
              </div>
            )}
            <div
              className={styles.flexBetweenContainer}
              style={{ marginTop: "12px" }}
            >
              <h2 className={styles.subheader}>{generateSubHeaderText()}</h2>
              <div className={styles.billingPeriodButtonContainer}>
                <button
                  type="button"
                  className={
                    selectedBillingPeriod === BillingPeriodUnit.Month
                      ? styles.selectedBillingPeriodButton
                      : styles.billingPeriodButton
                  }
                  onClick={() =>
                    setSelectedBillingPeriod(BillingPeriodUnit.Month)
                  }
                >
                  Monthly
                </button>
                <button
                  type="button"
                  className={
                    selectedBillingPeriod === BillingPeriodUnit.Year
                      ? styles.selectedBillingPeriodButton
                      : styles.billingPeriodButton
                  }
                  onClick={() =>
                    setSelectedBillingPeriod(BillingPeriodUnit.Year)
                  }
                >
                  Yearly
                </button>
              </div>
            </div>
          </div>
          <div className={styles.plansContainer}>
            {plans &&
              plans.map(plan => {
                const isPlanSelected = selectedPlan.id === plan.id;
                const abbreviatedPeriodUnit =
                  AbbreviatedPeriodUnits[
                    selectedPlan?.periodUnit as keyof typeof AbbreviatedPeriodUnits
                  ];
                return (
                  <div
                    key={plan.id}
                    className={
                      isPlanSelected
                        ? styles.selectedPlanCardContainer
                        : styles.planCardContainer
                    }
                    onClick={() => isSmallScreen && selectPlan(plan)}
                  >
                    <div className={styles.planCardHeaderContainer}>
                      <header>{plan.accessType}</header>
                      {isSmallScreen &&
                        (isPlanSelected ? (
                          <RadioInputChecked />
                        ) : (
                          <RadioInputUnchecked />
                        ))}
                    </div>
                    <div className={styles.planCardSubContainer}>
                      <div>
                        <div className={styles.planCardCostContainer}>
                          <h2>${centsToDollars(plan.monthlyCostInCents)}/mo</h2>
                          {plan?.periodUnit !== BillingPeriodUnit.Month && (
                            <p>
                              (Billed ${centsToDollars(plan.priceInCents)}/
                              {abbreviatedPeriodUnit})
                            </p>
                          )}
                        </div>
                        <BenefitsText plan={plan} />
                      </div>
                      {!isSmallScreen && (
                        <Button
                          onClick={() => selectPlan(plan)}
                          disabled={isPlanSelected}
                          className={styles.selectButton}
                        >
                          {isPlanSelected ? "Selected" : "Select"}
                        </Button>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className={styles.stickiedBottomButton}>
        <Button disabled={!selectedPlan} onClick={() => continueToCheckout()}>
          Continue to Checkout
        </Button>
      </div>
    </>
  );
}
